import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/inform',
    name: 'inform',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/information.vue')
  },
  {
    path: '/interests',
    name: 'interests',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/interests.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/package',
    name: 'package',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/package.vue')
  },
  {
    path: '/relation',
    name: 'relation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/relation.vue')
  }, {
    path: '/a',
    name: 'a',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/a.vue')
  }

]

const router = new VueRouter({
  routes
})
// 设置路由拦截
// router.beforeEach((to, from, next) => {
//   let token = ''
//   if (window.location.href.split('?')[1]) {
//     const query = window.location.href.split('?')[1]
//     const vars = query.split('&')
//     for (let i = 0; i < vars.length; i++) {
//       const pair = vars[i].split('=')
//       if (pair[0] === 'token') {
//         console.log(pair, 999)
//         token = pair[1].split('#')[0]
//         sessionStorage.setItem('token', pair[1].split('#')[0])
//       }
//     }
//     if (sessionStorage.getItem('token')) {
//       token = sessionStorage.getItem('token')
//     } else {
//       token = 888
//     }
//   }
//   console.log(sessionStorage.getItem('token'), 'beforeEach')
//   // 判断该路由是否需要登录权限
//   if (token || to.name === 'login') {
//     next()
//   } else {
//     next({ path: '/login' })
//   }
// })
export default router
