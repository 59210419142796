import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant, { Toast, Dialog } from 'vant'
import './assets/reset.css'
import 'vant/lib/index.css'
Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Toast)
Vue.use(Dialog)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
// const on = Vue.prototype.$on
// Vue.prototype.$on = function (event, func) {
//   let previous = 0
//   let newFunc = func
//   if (event === 'click') {
//     newFunc = function () {
//       const now = new Date().getTime()
//       if (previous + 1500 <= now) {
//         func.apply(this, arguments)
//         previous = now
//       }
//     }
//   }
//   on.call(this, event, newFunc)
// }
